import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { fetchAllBrokers, assignNewBroker } from "../../../actions";
import { ToastContainer, toast } from "react-toastify";
import Loader from '../../common/Loader';
import Checkbox from './../../common/Checkbox';
import "react-toastify/dist/ReactToastify.css";


class AdditionalConsultant extends Component {
  constructor(props) {
    super(props);
    this.state = {
        submitLoader: false,
        loader: true,
        selectedCheckboxes : new Set(),
        success: false
    };
  }
  componentDidMount() {
    try {
        this.props.fetchAllBrokers(2).then(() =>{
            this.setState({loader: false})
        })
    } catch (error) {

    }

  }
  renderConsultant(){
  
    if(this.state.success === false && this.props.broker_list.response_data && this.props.broker_list.response_data.length > 0){
      return (
        <ul className="list-group">
          {(()=>{
            return this.props.broker_list.response_data.map((broker, id) => {
                if(broker.id === this.props.activeItem.id){
                    return;
                }
              return (
              <li className="list-group-item">
                <Checkbox
                    label={broker.name}
                    companyId = {broker.id}
                    handleCheckboxChange={this.toggleCheckbox}
                    key={id}
                />
                
              </li>
              )
            })
          })()}
        </ul>
      )
    }
    else if(this.state.success){
      return(
        <div className="admin_invite_new_user">
          <div className="success_icon">
            <i className="far fa-check-circle"></i>
          </div>
          <div className="success_txt">{this.state.success}</div>
        </div>
      )
    }
    else{
      return(
        <p>There is no client associated with this consultant</p>
      )
    }
  }
  toggleCheckbox = companyId => {
    if (this.state.selectedCheckboxes.has(companyId)) {
      this.state.selectedCheckboxes.delete(companyId);
    } else {
      this.state.selectedCheckboxes.add(companyId);
    }
  }
  handleCLose() {
    this.props.closeModal();
  }
  assignConsultant = async () =>{
    this.setState({loader: true});
    for (const consultantId of this.state.selectedCheckboxes) {
        await this.props.assignNewBroker({
            data: {
              broker_id: consultantId,
              broker_type: 'Secondary'
            },
            company_id: this.props.companyData.id
          });
    }
    //this.setState({ loader: false });
    this.handleCLose();
    toast.success("Assigned Successfully", {onClose: this.props.history.goBack});

    

  }
  handleLoadBtn(){
    if(this.state.submitLoader===false && this.state.success === false){
      return <button class="custom-btn" onClick={e => { this.assignConsultant() }} >Assign</button>
    }
    else if(this.state.submitLoader){
      return(<button className="custom-btn ls" style={{"background":"#9a9d9e"}}>Assign
      <div className="spinner-border" role="status" style={{"marginLeft": "20px","width": "20px","height": "20px"}}>
        <span className="sr-only">Loading...</span>
      </div>
      </button>)
    }	
    else if(this.state.success === false){
      return(<button class="custom-btn" onClick={e => { this.createCompany(); }} > Assign </button>)
    }
  }
  renderLoader(){
    if(this.state.loader)
	{
		return <Loader />
	}
  }
  render() {
    return (
      <div className="modal-content">
         {this.renderLoader()}
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalCenterTitle">
            Assign Additional Consultant (Secondary)
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.history.goBack}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
            { this.renderConsultant()}
        </div>
        <div className="modal-footer text-center">
          <button
            class="custom-btn ml-3 cancel"
            onClick={this.props.history.goBack}
          >
            Close
          </button>
          {this.handleLoadBtn( )}
        </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeItem: state.activeDashboardItem,
    broker_list: state.broker_list
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchAllBrokers, assignNewBroker })(AdditionalConsultant)
);
