import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createProject,
  createProjectStickyNotes,
  fetchAllStickyNotesForProject,
  updateProjectStickyNotes,
  updateProjectFinalDocument,
  fetchProjectById,
  deleteStickyNoteForProject,
  deleteFinalDocumentForProject,
  updateStickyNoteStatusType,
  updateFinalDocumentStatusType,
  updateProjectStatusType,
  updateProjectStatus,
  moveToActive,
  updateProjectName,
  updateProjectTrelloCardID,
  deleteProject,
  checkBroker,
  activeDashboardItem,
  fetchBrokerCompanies,
  createProjectFinalDocument,
} from "../../../actions";
import { Link, withRouter, Redirect } from "react-router-dom";
import Loader from "../../common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateStickyModal from "../../common/CreateStickyModal";
import io from "socket.io-client";
import Cookies from "universal-cookie";
import SVGIcon from "../../common/SVGIcons";
import "react-quill/dist/quill.snow.css";
import { AWS_URL } from "../../../apis/api-config";
import { HashLink } from "react-router-hash-link";
import { updateStatusWithTrelloList } from "../dashboard/utils/DashboardContentUtils";
import { getAllTrelloListsIds, getListOfCard } from "../../../actions/trello";

const cookies = new Cookies();
const endpoint = cookies.get("api-url");
const socket = io(endpoint, {
  withCredentials: true,
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

class ProjectStatusContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameChange: false,
      editName: "",
      editTrelloCardID: "",
      delete: false,
      deleteSuccess: false,
      successMsg: "",
      showStickyNotes: false,
      loader: false,
      redirectWindow: false,
      conformDelte: false,
      conformDeleteType: "",
      coformDeleteText: "",
      deleteItemId: "",
      newStickyNotes: [],
      clientName: null,
      dataActiveOverlay: false,
      dataActiveItemId: "",
      dataActiveTitle: "",
      deataActiveDefaultTitle: "",
      dataActiveBody: "",
      dataActiveDefaultBody: "",
      dataTtitleChanged: false,
      dataDescChanged: false,
      redirectProjectWindow: false,
      statusData: {
        status: null,
        project: null,
        projectName: null,
      },
      projectUpdateStatus: false,
      projectUpdateStatusLoader: false,

      show_modal: {
        status: false,
        action_btn_text: null,
        title: null,
        type: null,
        dataTitle: "",
        dataDesc: "",
      },
      projectData: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      typeof this.props.activeProjectData.id !== "undefined" &&
      this.state.projectData === false
    ) {
      socket.on(this.props.activeProjectData.id, (data) => {
        this.setState({ projectData: data });
      });
      this.setState({ projectData: this.props.activeProjectData });
    }
    if (prevProps.activeProjectData.id !== this.props.activeProjectData.id) {
      socket.removeAllListeners(prevProps.activeProjectData.id);
      socket.on(this.props.activeProjectData.id, (data) => {
        this.setState({ projectData: data });
      });
      this.setState({
        projectData: this.props.activeProjectData,
        clientName: this.props.activeProjectData.EmployersOrg.Organization.name,
      });
    }
    if (
      typeof prevProps.activeProjectData.name !== "undefined" &&
      this.props.activeProjectData.name !== prevProps.activeProjectData.name
    ) {
      this.setState({ nameChange: false });
    }
    if (
      this.props.activeProjectData.name !== prevProps.activeProjectData.name
    ) {
      this.setState({ editName: this.props.activeProjectData.name });

      if (
        this.props.activeProjectData.trello_card_id &&
        this.props.trello.trello_lists_ids.length > 0 &&
        this.props.activeProjectData &&
        this.checkUserData() === 1
      ) {
        const canUpdateStatusWithTrello = updateStatusWithTrelloList(
          this.props.trello.trello_lists_ids,
          this.props.activeProjectData
        );
        if (canUpdateStatusWithTrello) {
          this.props
            .updateProjectStatus({
              status: canUpdateStatusWithTrello,
              poject: this.props.activeProjectData.id,
            })
            .then((res) => {
              if (res.response_type && res.response_type === "success") {
                const pathName = this.props.history.location.pathname;
                let currentStatus = pathName.split("/");
                currentStatus = currentStatus[currentStatus.length - 1];
                let currentPath = pathName.replace(
                  currentStatus,
                  canUpdateStatusWithTrello.replace(/ /g, "-")
                );
                this.props.history.push(currentPath);
                this.props.fetchProjectById({
                  id: this.state.statusData.project,
                  consultant_id: this.props.brokerName.id,
                });

                this.setState({
                  redirectWindowData: {},
                  project: "",
                  status: "",
                  projectUpdateStatusLoader: false,
                  redirectProjectWindow: false,
                  statusData: {
                    status: null,
                    project: null,
                    projectName: null,
                  },
                  projectUpdateStatus: false,
                  projectUpdateStatusResponse: "",
                });
                toast.success("Project status updated successfully.");
              }
            });
        }
      }
    }
    if (
      this.props.activeProjectData.trello_card_id !==
      prevProps.activeProjectData.trello_card_id
    ) {
      this.setState({
        editTrelloCardID: this.props.activeProjectData.trello_card_id,
      });
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (this.props.active) console.log("data", this.props.activeProjectData);
    //console.log("list ids", this.props.trello.trello_lists_ids);
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loader: true });
    this.props.getAllTrelloListsIds();
    this.props
      .checkBroker({ brokerId: this.props.match.params.consultant })
      .then((res) => {
        if (res.data.response_type != "success") {
          window.location = "/";
        }
        this.props.activeDashboardItem(res.data.response_data);
        this.props.fetchBrokerCompanies(res.data.response_data.id);

        this.props
          .fetchProjectById({
            id: this.props.match.params.id,
            consultant_id: this.props.brokerName.id,
          })
          .then((res) => {
            if (res.data.response_type != "success") {
              window.location = "/";
            }
            const pathName = this.props.history.location.pathname;
            let currentStatus = pathName.split("/");
            currentStatus = currentStatus[currentStatus.length - 1];
            let currentPath = pathName.replace(
              currentStatus,
              res.data.response_data.project_state.replace(/ /g, "-")
            );
            this.props.history.push(currentPath);

            this.setState({
              loader: false,
              clientName: res.data.response_data.EmployersOrg.Organization.name,
            });
          })
          .catch((err) => {
            if (
              err &&
              err.response !== undefined &&
              err.response.data !== undefined &&
              err.response.data.response_desc !== undefined
            ) {
              toast.error(err.response.data.response_desc);
              setTimeout(() => {
                window.location = "/";
              }, 3000);
            } else {
              window.location = "/";
            }
          });
      });
  }
  closeModal() {
    let newStatus = {
      action_btn_text: null,
      title: null,
      status: false,
      type: null,
    };
    this.setState({ show_modal: newStatus });
  }
  deleteProject() {
    this.setState({ loader: true });
    this.props
      .deleteProject({ id: this.props.activeProjectData.id })
      .then((res) => {
        this.setState({
          deleteSuccess: false,
          delete: false,
          // successMsg: res.response_desc,
          loader: false,
        });
        toast.success(res.response_desc, {
          onClose: this.props.history.goBack,
        });
      });
  }
  updateTitle() {
    this.setState({ loader: true });
    this.props
      .updateProjectName({
        id: this.props.activeProjectData.id,
        name: this.state.editName,
      })
      .then((res) => {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        toast.success(res.response_desc);
        this.setState({ nameChange: false, loader: false });
      });
  }
  updateTrelloCardID() {
    if (
      this.props.activeProjectData.trello_card_id !== "" &&
      this.props.activeProjectData.trello_card_id !==
        this.state.editTrelloCardID
    ) {
      this.setState({ loader: true });
      this.props
        .updateProjectTrelloCardID({
          id: this.props.activeProjectData.id,
          trello_card_id: this.state.editTrelloCardID,
        })
        .then((res) => {
          this.props.fetchProjectById({
            id: this.props.activeProjectData.id,
            consultant_id: this.props.brokerName.id,
          });
          toast.success(res.response_desc);
          this.setState({ nameChange: false, loader: false });
        });
    }
  }
  openNewStickyModal() {
    let newStatus = {
      action_btn_text: "Save",
      title: "Create New Sticky Note",
      status: true,
      files: [],
      type: "type",
      dataTitle: "",
      dataDesc: "",
      onSave: (data) => this.createNewStickyNote(data),
    };
    this.setState({ show_modal: newStatus });
  }
  openNewFinalDocument() {
    let newStatus = {
      action_btn_text: "Save",
      title: "Create New Final Document",
      status: true,
      files: [],
      type: "final-document",
      dataTitle: "",
      dataDesc: "",
      onSave: (data) => this.createNewFinalDocument(data),
    };
    this.setState({ show_modal: newStatus });
  }
  createNewFinalDocument(data) {
    this.setState({ loader: true });
    const formData = new FormData();
    data.uploadImages.forEach((file, index) => {
      formData.append("uploaded_files", file);
      formData.append("image_name[]", data.fileName[`name-${index}`]);
    });
    formData.append("position", 0);
    formData.append("project_id", this.props.activeProjectData.id);
    formData.append("title", data.title);
    if (data.description) {
      formData.append("description", data.description);
    }
    this.props.createProjectFinalDocument(formData).then((res) => {
      if (typeof res.response_type && res.response_type === "success") {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        //this.props.updateActiveProjectDetails(project);
        this.closeModal();
        toast.success(res.response_desc);
      } else {
        toast.error("Something was wrong");
      }
      this.setState({ loader: false });
    });
  }
  createNewStickyNote(data) {
    this.setState({ loader: true });
    const formData = new FormData();
    data.uploadImages.forEach((file, index) => {
      formData.append("uploaded_files", file);
      formData.append("image_name[]", data.fileName[`name-${index}`]);
    });
    formData.append("position", 0);
    formData.append("project_id", this.props.activeProjectData.id);
    formData.append("title", data.title);
    formData.append("description", data.description);

    this.props.createProjectStickyNotes(formData).then((res) => {
      if (typeof res.response_type && res.response_type === "success") {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        //this.props.updateActiveProjectDetails(project);
        this.closeModal();
        toast.success(res.response_desc);
      } else {
        toast.error("Something was wrong");
      }
      this.setState({ loader: false });
    });
  }
  handleDeleteStickyNote(type) {
    if (type === "final-document") {
      this.handleDeleteFinalDocument();
      return;
    }
    this.setState({ loader: true });
    if (this.state.coformDeleteText === "DELETE") {
      this.props
        .deleteStickyNoteForProject({ id: this.state.deleteItemId })
        .then((res) => {
          this.props.fetchProjectById({
            id: this.props.activeProjectData.id,
            consultant_id: this.props.brokerName.id,
          });
          toast.success(res.data.response_desc);
          this.setState({
            conformDelte: false,
            coformDeleteText: "",
            deleteItemId: "",
            loader: false,
          });
        });
    } else {
      toast.error("Type 'DELETE' in the input box to conform");
    }
  }
  handleDeleteFinalDocument() {
    this.setState({ loader: true });
    if (this.state.coformDeleteText === "DELETE") {
      this.props
        .deleteFinalDocumentForProject({ id: this.state.deleteItemId })
        .then((res) => {
          this.props.fetchProjectById({
            id: this.props.activeProjectData.id,
            consultant_id: this.props.brokerName.id,
          });
          toast.success(res.data.response_desc);
          this.setState({
            conformDelte: false,
            coformDeleteText: "",
            deleteItemId: "",
            loader: false,
          });
        });
    } else {
      toast.error("Type 'DELETE' in the input box to conform");
    }
  }

  handleMarkCompleteIncomplete(item, type) {
    if (this.checkUserData() === 1) {
      this.setState({ loader: true });
      let status = false;
      if (type === "complete") {
        status = true;
      }
      this.props
        .updateStickyNoteStatusType({ id: item.id, status: status })
        .then((res) => {
          this.props.fetchProjectById({
            id: this.props.activeProjectData.id,
            consultant_id: this.props.brokerName.id,
          });
          toast.success(res.data.response_desc);
          this.setState({
            conformDelte: false,
            coformDeleteText: "",
            deleteItemId: "",
            loader: false,
          });
        });
    }
  }
  handleMarkCompleteIncompleteDocument(item, type) {
    this.setState({ loader: true });
    let status = false;
    if (type === "complete") {
      status = true;
    }
    this.props
      .updateFinalDocumentStatusType({ id: item.id, status: status })
      .then((res) => {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        toast.success(res.data.response_desc);
        this.setState({
          conformDelte: false,
          coformDeleteText: "",
          deleteItemId: "",
          loader: false,
        });
      });
  }

  handleMoveToArchive(id, type) {
    this.setState({ loader: true });
    if (type === "Archived") {
      this.props
        .updateProjectStatusType({ id: id, status: type })
        .then((res) => {
          this.props.fetchProjectById({
            id: this.props.activeProjectData.id,
            consultant_id: this.props.brokerName.id,
          });
          toast.success(res.data.response_desc);
          this.setState({ deleteItemId: "", loader: false });
        });
    } else if (type === "Active") {
      this.props.moveToActive({ id: id, status: type }).then((res) => {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        toast.success(res.data.response_desc);
        this.setState({ deleteItemId: "", loader: false });
      });
    }
  }

  openEditModal(data) {
    let newStatus = {
      action_btn_text: "Update",
      title: "Update Sticky Note",
      status: true,
      dataId: data.id,
      dataTitle: data.title,
      dataDesc: data.description === "null" ? "" : data.description,
      files: data.files,
      type: "type",
      onSave: (data) => this.saveStickyContent(data),
    };
    this.setState({ show_modal: newStatus });
  }
  openFinalDocumentEditModal(data) {
    let newStatus = {
      action_btn_text: "Update",
      title: "Update Final Document",
      status: true,
      dataId: data.id,
      dataTitle: data.title,
      dataDesc: data.description === "null" ? "" : data.description,
      files: data.files,
      type: "type",
      onSave: (data) => this.updateFinalDocumentContent(data),
    };
    this.setState({ show_modal: newStatus });
  }

  saveStickyContent(item) {
    this.setState({ loader: true });
    const formData = new FormData();
    item.uploadImages.forEach((file, index) => {
      formData.append("uploaded_files", file);
      formData.append("image_name[]", item.fileName[`name-${index}`]);
    });
    formData.append("id", item.id);
    formData.append("title", item.title);
    formData.append("description", item.description);

    this.props.updateProjectStickyNotes(formData, item.id).then((res) => {
      if (typeof res.response_type && res.response_type === "success") {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        this.setState({
          loader: false,
        });
        this.closeModal();
        toast.success(res.response_desc);
      }
    });
  }
  updateFinalDocumentContent(item) {
    this.setState({ loader: true });
    const formData = new FormData();
    item.uploadImages.forEach((file, index) => {
      formData.append("uploaded_files", file);
      formData.append("image_name[]", item.fileName[`name-${index}`]);
    });
    formData.append("id", item.id);
    formData.append("title", item.title);
    if (item.description) {
      formData.append("description", item.description);
    }
    this.props.updateProjectFinalDocument(formData, item.id).then((res) => {
      if (typeof res.response_type && res.response_type === "success") {
        this.props.fetchProjectById({
          id: this.props.activeProjectData.id,
          consultant_id: this.props.brokerName.id,
        });
        this.setState({
          loader: false,
        });
        this.closeModal();
        toast.success(res.response_desc);
      }
    });
  }
  renderSaveAllChangesButton() {
    if (this.checkUserData() === 1) {
      if (
        typeof this.state.projectData.project_state !== "undefined" &&
        this.props.activeProjectData.status === "Active" &&
        (this.state.projectData.project_state === "Final" ||
          this.state.projectData.project_state === "Done")
      ) {
        return (
          <button
            className="custom-btn float-right"
            style={{ marginTop: "0px" }}
            onClick={(e) =>
              this.handleMoveToArchive(this.state.projectData.id, "Archived")
            }>
            {" "}
            Move to Archive{" "}
          </button>
        );
      } else if (
        typeof this.state.projectData.project_state !== "undefined" &&
        this.state.projectData.status === "Archived"
      ) {
        return (
          <button
            className="custom-btn float-right"
            style={{ marginTop: "0px" }}
            onClick={(e) =>
              this.handleMoveToArchive(this.state.projectData.id, "Active")
            }>
            {" "}
            Move to Active{" "}
          </button>
        );
      }
    }
  }

  renderProjectStatusIcons(status) {
    const statusArr = [
      "not",
      "not",
      "not",
      "not",
      "sub-item-not",
      "sub-item-not",
      "sub-item-not",
      "not",
      "not",
    ];
    let percent = 100 / 6;
    if (status === "Get Started" || status === "Start") {
      statusArr[0] = "active-current";
      percent = 100 / 6;
    } else if (status === "In Progress" || status === "A Few More Things") {
      statusArr[0] = "active";
      statusArr[1] = "active-current";
      percent = (100 / 6) * 2;
    } else if (status === "Draft" || status === "Drafting") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active-current";
      percent = (100 / 6) * 3;
    } else if (status === "Reviewing") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active-current";
      percent = (100 / 6) * 4;
    } else if (status === "Attorney Review") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active-current";
      statusArr[4] = "sub-item-current";
      statusArr[5] = "active";
      statusArr[6] = "active";
      percent = (100 / 6) * 4;
    } else if (status === "Consultant Review") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active-current";
      statusArr[4] = "active";
      statusArr[5] = "sub-item-current";
      statusArr[6] = "active";
      percent = (100 / 6) * 4;
    } else if (status === "Employer Review") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active-current";
      statusArr[4] = "active";
      statusArr[5] = "active";
      statusArr[6] = "sub-item-current";
      percent = (100 / 6) * 4;
    } else if (status === "Nearly There") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active";
      statusArr[4] = "active";
      statusArr[5] = "active";
      statusArr[6] = "active";
      statusArr[7] = "active-current";
      percent = (100 / 6) * 5;
    } else if (
      status === "Final" ||
      (status === "Done" && this.state.projectData.status === "Active")
    ) {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active";
      statusArr[4] = "active";
      statusArr[5] = "active";
      statusArr[6] = "active";
      statusArr[7] = "active";
      statusArr[8] = "active-current";
      percent = (100 / 6) * 6;
    } else if (status === "Final" || status === "Done") {
      statusArr[0] = "active";
      statusArr[1] = "active";
      statusArr[2] = "active";
      statusArr[3] = "active";
      statusArr[4] = "active";
      statusArr[5] = "active";
      statusArr[6] = "active";
      statusArr[7] = "active";
      statusArr[8] = "active-current";
      percent = (100 / 6) * 6;
    }
    const reviewingStates = {
      Reviewing: "Reviewing",
      "Attorney Review": "Attorney Review",
      "Consultant Review": "Consultant Review",
      "Employer Review": "Employer Review",
    };
    const reviewLabel = reviewingStates[status] ?? "Reviewing";
    const styleReviewingStates = {
      Reviewing: { paddingRight: 12, marginTop: 35 },
      "Attorney Review": { paddingRight: 0, marginTop: 35, marginLeft: 6 },
      "Consultant Review": { paddingRight: 0, marginTop: 35, marginLeft: 10 },
      "Employer Review": { paddingRight: 0, marginTop: 35, marginLeft: 9 },
    };
    const reviewTextStyle = styleReviewingStates[status] ?? {
      paddingRight: 12,
      marginTop: 35,
    };
    //this.props.activeProjectData
    return (
      <div className="col-12">
        <div className="d-flex d-md-none">
          <div className="col-8">
            <div className="progress " data-percentage={percent}>
              <span className="progress-left">
                <span className="progress-bar"></span>
              </span>
              <span className="progress-right">
                <span className="progress-bar"></span>
              </span>
              <div className="progress-value">
                <div>
                  {percent / (100 / 6)}
                  <span> of </span>6
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 auto",
              fontSize: "25px",
            }}>
            <span>{status}</span>
          </div>
        </div>
        <div className="d-none d-md-block">
          <div className="col-12 content-progress ">
            <div
              className={"item " + statusArr[0]}
              onClick={(event) => {
                this.handleStatusChange("Start");
              }}>
              <div className={"progress-project " + statusArr[0]}>
                <i className="fas  icon">
                  <SVGIcon name="step-1" className="svg-icon" />
                </i>
              </div>

              <div className={"text left "} style={{ marginLeft: "25px" }}>
                Start
              </div>
            </div>

            <div
              className={"item " + statusArr[1]}
              onClick={(event) => {
                this.handleStatusChange("A Few More Things");
              }}>
              <div className={"progress-project "}>
                <i className="fas icon">
                  <SVGIcon name="step-3" className="svg-icon" />
                </i>
              </div>
              <div
                className="text"
                style={{ width: "110%", marginLeft: "3px" }}>
                A Few More Things
              </div>
            </div>
            <div
              className={"item " + statusArr[2]}
              onClick={(event) => {
                this.handleStatusChange("Drafting");
              }}>
              <div className={"progress-project "}>
                <i className="fas icon">
                  <SVGIcon name="step-4" className="svg-icon" />
                </i>
              </div>
              <div className="text" style={{ paddingRight: 19 }}>
                Drafting
              </div>
            </div>
            <div className={"item " + statusArr[3]}>
              <div className={"progress-project not-clickable"}>
                <i className="fas icon">
                  <SVGIcon name="step-6" className="svg-icon" />
                </i>
              </div>
              <div className="vertical">
                <div
                  className={
                    statusArr[4] !== "sub-item-not"
                      ? "vertical-line-active"
                      : "vertical-line"
                  }></div>
              </div>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  paddingTop: 19,
                  marginLeft: -3,
                }}>
                <div
                  className={"sub-item " + statusArr[4]}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleStatusChange("Attorney Review");
                  }}></div>
                <div
                  className={
                    statusArr[5] !== "sub-item-not"
                      ? "horizontal-line-active"
                      : "horizontal-line"
                  }></div>
                <div
                  className={"sub-item " + statusArr[5]}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleStatusChange("Consultant Review");
                  }}></div>
                <div
                  className={
                    statusArr[6] !== "sub-item-not"
                      ? "horizontal-line-active"
                      : "horizontal-line"
                  }></div>
                <div
                  className={"sub-item " + statusArr[6]}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleStatusChange("Employer Review");
                  }}></div>
              </div>
              <div className="text" style={reviewTextStyle}>
                {reviewLabel}
              </div>
            </div>
            <div
              className={"item " + statusArr[7]}
              onClick={(event) => {
                this.handleStatusChange("Nearly There");
              }}>
              <div className={"progress-project "}>
                <i className="fas icon">
                  <SVGIcon name="step-7" className="svg-icon" />
                </i>
              </div>
              <div className="text" style={{ paddingRight: 5 }}>
                Nearly There
              </div>
            </div>
            <div
              className={"item " + statusArr[8]}
              onClick={(event) => {
                this.handleStatusChange("Done");
              }}>
              <div className={"progress-project "}>
                <i className="fas icon">
                  <SVGIcon name="step-5" className="svg-icon" />
                </i>
              </div>
              <div className="text" style={{ paddingRight: 24 }}>
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleStatusChange(status) {
    if (this.checkUserData() === 1) {
      this.setState({
        redirectProjectWindow: true,
        statusData: {
          status,
          project: this.state.projectData.id,
          projectName: this.state.projectData.name,
        },
      });
    }
  }
  renderProjectStatus() {
    const scrollWithOffset = (el) => {
      const observer = new MutationObserver((mutations, obs) => {
        const loader = document.getElementById("loadererisa");
        if (!loader) {
          const newElement = document.getElementById(el.id);
          newElement.scrollIntoView();
          obs.disconnect();
          return;
        }
      });

      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    };
    if (
      this.state.projectData &&
      Object.keys(this.state.projectData).length > 0
    ) {
      document.title = `${this.state.projectData.name} - ${this.state.projectData.project_state}`;
      let trelloCardID = <></>;
      if (
        !this.state.projectData.trello_card_id &&
        this.checkUserData() === 1
      ) {
        trelloCardID = (
          <div
            className="col-12"
            style={{ marginLeft: "21px", marginTop: "10px" }}>
            <h6>No Trello Card URL found</h6>
          </div>
        );
      }
      if (this.state.projectData.trello_card_id && this.checkUserData() === 1) {
        trelloCardID = (
          <div
            className="col-12"
            style={{ marginLeft: "21px", marginTop: "10px" }}>
            <h6>
              <b>Trello Card URL: </b>{" "}
              <a href={this.state.projectData.trello_card_id} target="_blank">
                {this.state.projectData.trello_card_id}{" "}
              </a>
            </h6>
          </div>
        );
      }

      let projectName = (
        <h5>
          <HashLink
            to={`/consultant/${this.props.match.params.consultant}/#company_${this.state.projectData.emporg_id}`}
            scroll={(el) => scrollWithOffset(el)}>
            <i style={{ color: "black" }} className="fas fa-chevron-left"></i>
          </HashLink>
          {this.state.projectData.name}
          <span> | </span>
          <span> {this.state.clientName} </span>
          <span> {trelloCardID} </span>
        </h5>
      );
      if (this.state.nameChange) {
        projectName = (
          <h5>
            <div className="form-inline">
              <i
                className="fas fa-chevron-left"
                onClick={this.props.history.goBack}></i>
              <div className="form-group mx-sm-3 mb-2">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ editName: e.target.value });
                  }}
                  id="project-title"
                  value={this.state.editName}
                  placeholder="Enter Project Name"
                />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ editTrelloCardID: e.target.value });
                  }}
                  id="project-title"
                  value={this.state.editTrelloCardID}
                  placeholder="Enter Trello Card URL"
                />
              </div>
              <div className="btnRow">
                <button
                  style={{ padding: "0.4rem 4rem", marginTop: "0px" }}
                  onClick={() => {
                    this.updateTitle();
                    this.updateTrelloCardID();
                  }}
                  className="btn custom-btn mb-2">
                  Update
                </button>
              </div>
            </div>
          </h5>
        );
      }
      return (
        <div className="row dashboardContainer" style={{ clear: "both" }}>
          <div className="col-12 content-title">{projectName}</div>

          {this.renderProjectStatusIcons(this.state.projectData.project_state)}
        </div>
      );
    }
  }
  handleProjectStatus() {
    this.setState({ projectUpdateStatusLoader: true });
    this.props
      .updateProjectStatus({
        status: this.state.statusData.status,
        poject: this.state.statusData.project,
      })
      .then((res) => {
        if (res.response_type && res.response_type === "success") {
          const pathName = this.props.history.location.pathname;
          let currentStatus = pathName.split("/");
          currentStatus = currentStatus[currentStatus.length - 1];
          let currentPath = pathName.replace(
            currentStatus,
            this.state.statusData.status.replace(/ /g, "-")
          );
          this.props.history.push(currentPath);
          this.props.fetchProjectById({
            id: this.state.statusData.project,
            consultant_id: this.props.brokerName.id,
          });

          this.setState({
            redirectWindowData: {},
            project: "",
            status: "",
            projectUpdateStatusLoader: false,
            redirectProjectWindow: false,
            statusData: {
              status: null,
              project: null,
              projectName: null,
            },
            projectUpdateStatus: false,
            projectUpdateStatusResponse: "",
          });
          toast.success("Project status updated successfully.");
        }
      });
  }
  deleteConfirmBox() {
    if (this.state.delete) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>Are you sure, you want to delete?</p>
            <div className="btnRow">
              <button
                className="custom-btn"
                onClick={() => {
                  this.deleteProject();
                }}>
                Delete
              </button>
              <button
                className="custom-btn ml-3 cancel"
                onClick={(e) => {
                  this.setState({ delete: false });
                }}>
                Cancel
              </button>
            </div>
          </div>
          {this.renderLoader()}
        </div>
      );
    } else if (this.state.deleteSuccess) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <div
              className="success_icon"
              style={{ color: "green", fontSize: "2rem" }}>
              <i className="far fa-check-circle"></i>
            </div>
            <div
              className="success_txt"
              style={{ fontSize: "1rem", margin: "1rem" }}>
              {this.state.successMsg}
            </div>
            <div className="btnRow">
              <a className="custom-btn ml-3 cancel" href="/">
                Close
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
  renderRedirectStatusWindow() {
    if (this.state.redirectProjectWindow) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <h4>Update Project Status</h4>
            {(() => {
              if (!this.state.projectUpdateStatus) {
                return (
                  <div>
                    <p
                      style={{
                        "padding-top": "20px",
                        "padding-bottom": "20px",
                      }}>
                      Continue to update project status to{" "}
                      <b> {this.state.statusData.status} </b>
                    </p>
                    <div className="btnRow">
                      <button
                        className="custom-btn"
                        onClick={(e) => this.handleProjectStatus()}>
                        Continue
                      </button>
                      <button
                        className="custom-btn ml-3 cancel"
                        onClick={(e) => {
                          this.setState({
                            redirectProjectWindow: false,
                            statusData: {
                              status: null,
                              project: null,
                              projectName: null,
                            },
                          });
                        }}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              } else if (this.state.projectUpdateStatus) {
                return (
                  <div>
                    <p>{this.state.projectUpdateStatusResponse}</p>
                    <div className="btnRow">
                      <button
                        className="custom-btn"
                        onClick={(e) => {
                          this.setState({
                            redirectProjectWindow: false,
                            statusData: {
                              status: null,
                              project: null,
                              projectName: null,
                            },
                            projectUpdateStatus: false,
                            projectUpdateStatusResponse: "",
                          });
                        }}>
                        Continue
                      </button>
                    </div>
                  </div>
                );
              }
            })()}

            {(() => {
              if (this.state.projectUpdateStatusLoader) {
                return <Loader />;
              }
            })()}
          </div>
        </div>
      );
    }
  }

  renderFinalDocument(type) {
    if (
      this.state.projectData &&
      Object.keys(this.state.projectData).length > 0
    ) {
      return this.state.projectData.FinalDocuments.map((item, index) => {
        if (type === "incomplete" && !item.complete) {
          let markComplete;
          let status = false;
          let dataActiveOverlay = false;
          let actionButtons = "";
          let title = item.title;

          let description = (
            <div
              key={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              className="text-content ql-editor"
              id={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              dangerouslySetInnerHTML={{
                __html: item.description === "null" ? "" : item.description,
              }}></div>
          );

          if (item.complete) {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncompleteDocument(item, "incomplete");
                }}>
                Mark as Incomplete
              </div>
            );
          } else {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncompleteDocument(item, "complete");
                }}>
                Mark as Complete
              </div>
            );
          }

          return (
            <div key={item.id} className="s-notes col-sm-6 col-xl-4">
              <div
                key={item.id}
                className="note-tile ql-snow final-document"
                data-active-container={dataActiveOverlay}>
                <div className="sticky-note">
                  <i>
                    <img
                      src={"/assets/img/icons/doc.png"}
                      alt="final-docuemnt icon"
                    />
                  </i>

                  <h5>
                    {title}
                    {this.checkUserData() === 1 && (
                      <div
                        className="btn-group btn-action position-absolute"
                        style={{ right: "25px" }}>
                        <button
                          type="button"
                          className="btn btn-danger dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="fas fa-ellipsis-h create"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-left">
                          <div
                            className="dropdown-item"
                            onClick={() =>
                              this.openFinalDocumentEditModal({
                                title: item.title,
                                description: item.description,
                                id: item.id,
                                files: item.StickyImages,
                              })
                            }>
                            Edit
                          </div>

                          <div
                            className="dropdown-item"
                            onClick={(e) =>
                              this.setState({
                                conformDelte: true,
                                conformDeleteType: "final-document",
                                deleteItemId: item.id,
                              })
                            }>
                            Delete
                          </div>
                        </div>
                      </div>
                    )}
                  </h5>
                </div>
                {description}
                {actionButtons}
                {this.displayFiles(item.StickyImages)}
              </div>
            </div>
          );
        } else if (type === "complete" && item.complete) {
          let markComplete;
          let status = false;
          let dataActiveOverlay = false;
          let actionButtons = "";
          let title = item.title;

          let description = (
            <div
              key={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              className="ql-editor text-content"
              id={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              dangerouslySetInnerHTML={{
                __html: item.description === "null" ? "" : item.description,
              }}></div>
          );
          if (item.complete) {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncompleteDocument(item, "incomplete");
                }}>
                Mark as Incomplete
              </div>
            );
          } else {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncompleteDocument(item, "complete");
                }}>
                Mark as Complete
              </div>
            );
          }

          return (
            <div key={item.id} className="s-notes col-sm-6 col-xl-4">
              <div
                className=" note-tile completed final-document"
                data-active-container={dataActiveOverlay}>
                <div className="sticky-note">
                  <i>
                    <img
                      src={"/assets/img/icons/doc.png"}
                      alt="final-docuemnt icon"
                    />
                  </i>

                  <h5>
                    {title}
                    {this.checkUserData() === 1 && (
                      <div
                        className="btn-group btn-action"
                        style={{ position: "absolute", right: "25px" }}>
                        <button
                          type="button"
                          className="btn btn-danger dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="fas fa-ellipsis-h create"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-left">
                          <div
                            className="dropdown-item"
                            onClick={(e) =>
                              this.setState({
                                conformDelte: true,
                                conformDeleteType: "final-document",
                                deleteItemId: item.id,
                              })
                            }>
                            Delete
                          </div>
                        </div>
                      </div>
                    )}
                  </h5>
                </div>
                <div className="text-content ql-snow">
                  {description}
                  {this.displayFiles(item.StickyImages)}
                </div>
              </div>
            </div>
          );
        }
      });
    }
  }
  displayFiles(stickyImages) {
    if (stickyImages && stickyImages.length) {
      const imageList = stickyImages.map((item, index) => {
        return (
          <li key={index}>
            <a href={AWS_URL + item.image_path}>{item.name}</a>
          </li>
        );
      });
      return (
        <div>
          <ul className="p-3">{imageList}</ul>
        </div>
      );
    }
  }
  renderStickyNotes(type) {
    if (
      this.state.projectData &&
      Object.keys(this.state.projectData).length > 0
    ) {
      return this.state.projectData.StickyNotes.map((item, index) => {
        if (type === "incomplete" && !item.complete) {
          let markComplete;
          let status = false;
          let dataActiveOverlay = false;
          let actionButtons = "";
          let title = item.title;

          let description = (
            <div
              key={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              className="text-content ql-editor"
              id={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              dangerouslySetInnerHTML={{
                __html: item.description === "null" ? "" : item.description,
              }}></div>
          );

          if (item.complete) {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncomplete(item, "incomplete");
                }}>
                Mark as Incomplete
              </div>
            );
          } else {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncomplete(item, "complete");
                }}>
                Mark as Complete
              </div>
            );
          }

          return (
            <div key={item.id} className="s-notes col-sm-6 col-xl-4">
              <div
                key={item.id}
                className="note-tile ql-snow"
                data-active-container={dataActiveOverlay}>
                <h5>
                  <div
                    className="text-right mark-active"
                    onClick={(e) => {
                      this.handleMarkCompleteIncomplete(item, "complete");
                    }}>
                    <img
                      src="/assets/img/icons/empty-circle.png"
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                  </div>
                  {title}
                  {this.checkUserData() === 1 && (
                    <div
                      className="btn-group btn-action position-absolute"
                      style={{ right: "25px" }}>
                      <button
                        type="button"
                        className="btn btn-danger dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="fas fa-ellipsis-h create"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-left">
                        <div
                          className="dropdown-item"
                          onClick={() =>
                            this.openEditModal({
                              title: item.title,
                              description: item.description,
                              id: item.id,
                              files: item.StickyImages,
                            })
                          }>
                          Edit
                        </div>
                        {markComplete}
                        <div
                          className="dropdown-item"
                          onClick={(e) =>
                            this.setState({
                              conformDelte: true,
                              conformDeleteType: "sticky",
                              deleteItemId: item.id,
                            })
                          }>
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                </h5>
                {description}
                {actionButtons}
                {this.displayFiles(item.StickyImages)}
              </div>
            </div>
          );
        } else if (type === "complete" && item.complete) {
          let markComplete;
          let status = false;
          let dataActiveOverlay = false;
          let actionButtons = "";
          let title = item.title;
          const activeIcon = (
            <div
              className="text-right mark-active"
              onClick={(e) => {
                this.handleMarkCompleteIncomplete(item, "incomplete");
              }}>
              <img
                src="/assets/img/icons/check.png"
                style={{ width: "24.5px", marginRight: "8px" }}
              />
            </div>
          );
          let description = (
            <div
              key={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              className="ql-editor text-content"
              id={
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
              }
              dangerouslySetInnerHTML={{
                __html: item.description === "null" ? "" : item.description,
              }}></div>
          );
          if (item.complete) {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncomplete(item, "incomplete");
                }}>
                Mark as Incomplete
              </div>
            );
          } else {
            markComplete = (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  this.handleMarkCompleteIncomplete(item, "complete");
                }}>
                Mark as Complete
              </div>
            );
          }

          return (
            <div key={item.id} className="s-notes col-sm-6 col-xl-4 ">
              <div
                className="note-tile completed"
                data-active-container={dataActiveOverlay}>
                <h5>
                  {activeIcon}
                  {title}
                  {this.checkUserData() === 1 && (
                    <div
                      className="btn-group btn-action"
                      style={{ position: "absolute", right: "25px" }}>
                      <button
                        type="button"
                        className="btn btn-danger dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="fas fa-ellipsis-h create"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-left">
                        {markComplete}
                        <div
                          className="dropdown-item"
                          onClick={(e) =>
                            this.setState({
                              conformDelte: true,
                              conformDeleteType: "sticky",
                              deleteItemId: item.id,
                            })
                          }>
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                </h5>
                <div className="text-content ql-snow">
                  {description}
                  {this.displayFiles(item.StickyImages)}
                </div>
              </div>
            </div>
          );
        }
      });
    }
  }

  renderRedirectWindow() {
    if (this.state.redirectWindow) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <p>
              All changes have been saved successfully. You can continue work on
              the current project or go back to the dashboard.
            </p>
            <div className="btnRow">
              <Link className="custom-btn" to="/">
                Go to Dashboard
              </Link>
              <button
                className="custom-btn ml-3 cancel"
                onClick={(e) => {
                  this.setState({ redirectWindow: false });
                }}>
                Continue Working
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderDeleteWindow(type) {
    if (this.state.conformDelte) {
      type = this.state.conformDeleteType;
      let title = <h4>Delete Sticky Note</h4>;
      let content = (
        <p style={{ marginBottom: "30px" }}>
          You are about to delete a sticky note, in order to complete your
          request type <b>Delete</b> below.
        </p>
      );
      if (type === "final-document") {
        title = <h4>Delete Final Document</h4>;
        content = (
          <p style={{ marginBottom: "30px" }}>
            You are about to delete a Final Document, in order to complete your
            request type <b>Delete</b> below.
          </p>
        );
      }
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            {title}
            {content}
            <div
              className="form-group"
              style={{
                maxWidth: "240px",
                margin: "auto",
                marginBottom: "60px",
              }}>
              <input
                type="text"
                className="form-control"
                placeholder="Type DELETE"
                onChange={(event) =>
                  this.setState({ coformDeleteText: event.target.value })
                }
              />
            </div>
            <div className="btnRow">
              <button
                className="custom-btn"
                onClick={(e) => this.handleDeleteStickyNote(type)}>
                Continue
              </button>
              <button
                className="custom-btn ml-3 cancel"
                onClick={(e) => {
                  this.setState({ conformDelte: false, coformDeleteText: "" });
                }}>
                Cancel
              </button>
            </div>
          </div>
          {this.renderLoader()}
        </div>
      );
    }
  }

  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  renderLoader() {
    if (this.state.loader) {
      return <Loader position={"fixed"} />;
    }
  }

  render() {
    return (
      <div className="col-12 col-sm-9 col-md-9 col-xl-9 content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <CreateStickyModal
          show_modal={this.state.show_modal}
          successTitle="Sticky Note"
          closeModal={() => this.closeModal()}
        />

        <div className="row cp">
          <div
            className="col default create-temp"
            style={{ paddingTop: "1rem" }}
            data-active-overlay={this.state.dataActiveOverlay}>
            <div className="row justify-content-between">
              <h5 className="col-2 col-sm-2"></h5>
              <div className="col-12 col-sm-8">
                {this.renderSaveAllChangesButton()}
                {this.checkUserData() === 1 && (
                  <>
                    <button
                      className="warning-btn float-right mr-2"
                      style={{ marginTop: "0px" }}
                      onClick={(e) => this.setState({ delete: true })}>
                      {" "}
                      Delete{" "}
                    </button>
                    <button
                      className="secondary-btn float-right mr-2"
                      style={{ marginTop: "0px" }}
                      onClick={() => {
                        this.setState({ nameChange: true });
                      }}>
                      {" "}
                      Edit{" "}
                    </button>
                  </>
                )}
              </div>
            </div>

            {this.renderProjectStatus()}
            <div className="row dashboardContainer margin-top">
              <div className="col-12 content-title border-bottm">
                <h5 className="things-to-do project">
                  Things To Do{" "}
                  {this.checkUserData() === 1 && (
                    <span>
                      <span
                        ref="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="fas fa-plus"></i> Add New
                      </span>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink">
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.openNewStickyModal()}
                          href="#">
                          Sticky Note
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={(e) => this.openNewFinalDocument()}
                          href="#">
                          Final Document
                        </a>
                      </div>
                    </span>
                  )}
                </h5>
              </div>
              <div className="col-12  content-notes">
                {/* this.renderNewStickyNotes() */}
                <div className="row m-0 content-notes">
                  {this.renderFinalDocument("incomplete")}
                  {this.renderFinalDocument("complete")}
                  {this.renderStickyNotes("incomplete")}
                  {this.renderStickyNotes("complete")}
                </div>

                {/* <div className="custom-btn">View More Notes</div> */}
              </div>
            </div>
          </div>
        </div>
        {this.renderLoader()}
        {this.renderRedirectWindow()}
        {this.renderDeleteWindow()}
        {this.renderRedirectStatusWindow()}
        {this.deleteConfirmBox()}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    brokerName: state.activeDashboardItem,
    activeProjectData: state.activeProject,
    trello: state.trello,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    activeDashboardItem,
    fetchAllStickyNotesForProject,
    createProject,
    createProjectStickyNotes,
    createProjectFinalDocument,
    updateProjectStickyNotes,
    updateProjectFinalDocument,
    fetchProjectById,
    deleteStickyNoteForProject,
    deleteFinalDocumentForProject,
    updateStickyNoteStatusType,
    updateFinalDocumentStatusType,
    updateProjectStatusType,
    updateProjectStatus,
    moveToActive,
    updateProjectName,
    updateProjectTrelloCardID,
    getAllTrelloListsIds,
    getListOfCard,
    deleteProject,
    checkBroker,
    fetchBrokerCompanies,
  })(ProjectStatusContent)
);
