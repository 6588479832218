import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import {createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from "react-router-dom";
import reducers from './reducers';
import App from './components/App';

import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";
import { BASE_URL, DOMAIN, CLIENT_ID, AUTH_SCOPE} from "./apis/api-config";
import { composeWithDevTools } from '@redux-devtools/extension';
import './global.css';

Sentry.init({
  dsn: "https://46cc61db30e9d0788b6e9cc35a90fdb3@o437367.ingest.us.sentry.io/4507935443189760",
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.browserProfilingIntegration(),
    // Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/erisafire-stage\.herokuapp\.com/,
    /^https:\/\/erisafire-stage-api\.herokuapp\.com/,
    /^https:\/\/projects\.erisafire\.com/,
    /^https:\/\/erisafire-node\.herokuapp\.com/,
  ],
  profilesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});
// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};


const store = createStore(reducers,composeWithDevTools(applyMiddleware(thunk)) );

ReactDOM.render(
  <Provider store={store} >
  <BrowserRouter basename={'/'} forceRefresh={false}>
    <Auth0Provider
    domain={DOMAIN}
    client_id={CLIENT_ID}
    redirect_uri={window.location.origin +'/callback'}
    scope= {AUTH_SCOPE}
    onRedirectCallback={onRedirectCallback}
  >
      <App />
    </Auth0Provider>
	</BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
