import React, { Component } from "react";
import { setStatus } from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isModuleSpecifier } from "@babel/types";
import ReactTooltip from 'react-tooltip'

class StickyNavBar extends Component {
  constructor(props){
    super(props);
    this.state = { displayTool: true }
  }
  resetActiveMenu() {
    this.props.setStatus({ type: "ACTIVE_DASHBOARD_ITEM", data: null });
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.org[0].Organization.name;
    }
  }
  renderToolTip(){
    if(this.state.displayTool){
      return (
        <ReactTooltip id='navTool' />
      )
    }
    else{
      return (
        <ReactTooltip id='navTool' getContent={() => { return null }}/>
      )
    }
  }
  render() {
    var displayTemp = 'd-none'
    if(this.props.user && this.props.user.type_id === 1)
    {
      displayTemp = ''
    }
    return (
      <div className="stickynav-left d-lg-block d-none unexpanded">
        {this.renderToolTip()}
        <Link to="/">
          <img
            src="/assets/img/icons/logo.png"
            className="img-fluid"
            alt="ERISAFIRE"
          />
        </Link>
       
        <ul className="list-style-none">
          <li>
            <Link to="/"  
            onClick={() => this.resetActiveMenu()} 
            data-tip='Home' 
            data-place = "right"  
            data-for='navTool'
            data-offset = "{'background-color': 'blue'}"
            data-type='success'
            style={{maxWidth: "3rem"}}
            >
              <img
                src="/assets/img/icons/home.png"
                className="img-fluid"
                alt="Home"
              />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/compliance-Calendar" data-tip='Compliance Calendar' 
            data-place = "right"  
            data-for='navTool'
            data-offset = "{'background-color': 'blue'}"
            style={{maxWidth: "3rem"}}
            data-type='success'>
              <img
                src="/assets/img/icons/calander.png"
                className="img-fluid"
                alt="Compliance Calendar"
                
              />
              <span>Compliance Calendar</span>
            </Link>
          </li>
          <li>
            <Link to="/members" onClick={() => this.resetActiveMenu()} data-tip='Members' 
            data-place = "right"  
            data-for='navTool'
            data-offset = "{'background-color': 'blue'}"
            style={{maxWidth: "3rem"}}
            data-type='success'>
              <img
                src="/assets/img/icons/s6.png"
                className="img-fluid"
                alt="Members"
                
              />
              <span>Members</span>
            </Link>
          </li>
          
          <li className={displayTemp}>
            <Link to="/settings" onClick={() => this.resetActiveMenu()} data-tip='App Settings' 
            data-place = "right"  
            data-for='navTool'
            style={{maxWidth: "3rem"}}
            data-type='success' >
            <img
            src="/assets/img/icons/gear.png"
            className="img-fluid"
            alt="App Settings"
          
          />
              <span>Templates</span>
            </Link>
          </li>
        </ul>
        <div className="viewToggle d-none" onClick={() => this.setState({ displayTool: !this.state.displayTool })}>
          <i className="fas fa-angle-double-right"></i>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, { setStatus })(StickyNavBar);
