import React, { Component } from 'react';
import TrelloListsIDTable from './TrelloListsIDTable';
import { connect } from "react-redux";
import { getAllTrelloListsIds } from '../../../actions/trello';
import TrelloIntegrationForm from './TrelloIntegrationForm';
import Loader from '../../common/Loader';
import { withRouter } from "react-router-dom";

class TrelloIntegration extends Component {
    componentDidMount(){
        this.props.getAllTrelloListsIds();
    }
    render() {
        return (
            <div className="row contentArea mr-0">
                {this.props.trello.loading ===true ? <Loader/> : <></>}
			    <div className="col-12 content full">
				    <div className="row cp">
					    <div className="col default selectTemp">
                            <div className="goback" onClick={this.props.history.goBack} ><i className="fas fa-long-arrow-alt-left"></i> Go Back</div>
                            <TrelloIntegrationForm trello_lists_ids ={this.props.trello.trello_lists_ids}/>
                            <TrelloListsIDTable trello_lists_ids ={this.props.trello.trello_lists_ids}/>
					    </div>
				    </div>
			    </div>
		   </div>
        );
    }
}

function mapStateToProps(state) {
  
    return {
        trello:state.trello
    };
  }
export default withRouter(connect(mapStateToProps, {getAllTrelloListsIds})(TrelloIntegration))