import React, { Component } from "react";
import { searchTrelloListId } from "../../../actions/trello";
import { connect } from "react-redux";

class TrelloIntegrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { trello_list_id_value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {}
  handleSubmit(event) {
    event.preventDefault();
    this.props.searchTrelloListId(
      this.state.trello_list_id_value,
      this.props.trello_lists_ids
    );
  }
  handleChange(event) {
    this.setState({ trello_list_id_value: event.target.value });
  }
  render() {
    return (
      <form
        className='form-inline justify-content-center'
        style={{ marginBottom: "50px" }}
        onSubmit={this.handleSubmit}
      >
        <div className='form-group mx-sm-3 mb-2'>
          <label htmlFor='inputPassword2' className='sr-only'>
            Trello List ID
          </label>
          <input
            type='text'
            className='form-control'
            id='inputPassword2'
            placeholder='Trello List ID'
            value={this.state.trello_list_id_value}
            onChange={this.handleChange}
          />
        </div>
        <button type='submit' className='custom-btn mb-2 mt-0 ml-0 mr-0'>
          Add Trello List ID
        </button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    trello_lists_ids: state.trello,
  };
}
export default connect(mapStateToProps, { searchTrelloListId })(
  TrelloIntegrationForm
);
