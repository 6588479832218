import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  fetchAllClientsForActiveBroker,
  checkBroker,
  fetchBrokerCompanies,
  activeDashboardItem,
  updateActiveComapny,
  updateProjectStatus,
  updateActiveProjectDetails,
  fetchAllRecentProjects,
  markFavorite,
  unMarkFavorite,
  fetchAllUsersForActiveBroker,
  fetchAllBrokersForActiveClient,
  updateProjectStatusType,
  showFavClients,
  showArchivedProjects,
} from "../../../actions";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader";
import Cookies from "universal-cookie";
import io from "socket.io-client";
import ProjectCard from "./projectCard";
import {
  isThreeMonthsOlder,
  updateStatusWithTrelloList,
} from "./utils/DashboardContentUtils";
import { getAllTrelloListsIds, getListOfCard } from "../../../actions/trello";

const cookies = new Cookies();
const endpoint = cookies.get("api-url");
const socket = io(endpoint, {
  withCredentials: true,
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

class DashboardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBroker: null,
      redirectWindow: false,
      redirectWindowData: {},
      projectUpdateStatus: false,
      projectUpdateStatusResponse: "",
      projectUpdateStatusLoader: false,
      status: "",
      poject: "",
      showAll: true,
      redirect: false,
    };
    this.isMountedVal = 0;
  }
  companyList = new Set();
  handleActiveCompany(company) {
    this.props.updateActiveComapny(company);
  }
  handleRedirectionToProjectEditPage(project) {
    var projectState = project.project_state.replace(/[\W_]/g, "-");
    var projectSlug = project.slug
      ? project.slug
      : project.name.replace(/[\W_]/g, "-");
    let target = {
      pathname: `/${project.broker_url}/${project.client_url}/${project.id}/${projectSlug}/${projectState}`,
      state: { project: project },
    };

    this.props.history.push(target);
  }
  handleProjectStatus() {
    this.setState({ projectUpdateStatusLoader: true });
    this.props
      .updateProjectStatus({
        status: this.state.status,
        poject: this.state.poject,
      })
      .then((res) => {
        if (res.response_type && res.response_type === "success") {
          this.props.fetchBrokerCompanies(this.props.activeItem.id);
          this.props.fetchAllRecentProjects({
            broker_id: this.props.activeItem.id,
          });
          this.setState({
            redirectWindowData: {},
            project: "",
            status: "",
            projectUpdateStatusLoader: false,
            projectUpdateStatus: true,
            projectUpdateStatusResponse: res.response_desc,
          });

          //this.props.fetchBroker();
        }
      });
  }
  handleShowAllProject() {
    this.state.showAll === true
      ? this.setState({ showAll: false })
      : this.setState({ showAll: true });
  }
  handleShowFavorite() {
    if (this.props.favClients === true) {
      this.props.showFavClients(false);
      window.localStorage.setItem(
        "ERISA_SHOW_FAVORITE_CLIENTS",
        JSON.stringify(false)
      );
    } else {
      this.props.showFavClients(true);
      window.localStorage.setItem(
        "ERISA_SHOW_FAVORITE_CLIENTS",
        JSON.stringify(true)
      );
    }
  }
  handleShowArchivedProject() {
    if (this.props.archived === true) {
      this.props.showArchivedProjects(false);
      window.localStorage.setItem(
        "ERISA_SHOW_ARCHIVED_PROJECTS",
        JSON.stringify(false)
      );
    } else {
      this.props.showArchivedProjects(true);
      window.localStorage.setItem(
        "ERISA_SHOW_ARCHIVED_PROJECTS",
        JSON.stringify(true)
      );
    }
  }

  componentDidMount() {
    this.isMountedVal = 1;
    //this.props.fetchBrokerCompanies(this.props.match.params.name);
    //this.props.activeDashboardItem(this.props.match.params.name);
    this.props
      .checkBroker({ brokerId: this.props.match.params.name })
      .then(async (res) => {
        if (res.data.response_type === "success") {
          const brokerDetails = res.data.response_data;

          socket.on(`broker-${brokerDetails.id}`, (data) => {
            this.props.fetchBrokerCompanies(brokerDetails.id);
            //this.props.fetchAllRecentProjects({ broker_id: brokerDetails.id });
          });
          this.props.activeDashboardItem(brokerDetails);
          this.props.fetchAllRecentProjects({ broker_id: brokerDetails.id });
          const brokerData = await this.props.fetchBrokerCompanies(
            brokerDetails.id
          );
          this.setState({ redirect: true });
        }
      });
    this.props.getAllTrelloListsIds();
    this.props.showFavClients(
      window.localStorage.getItem("ERISA_SHOW_FAVORITE_CLIENTS")
        ? JSON.parse(window.localStorage.getItem("ERISA_SHOW_FAVORITE_CLIENTS"))
        : true
    );
    this.props.showArchivedProjects(
      window.localStorage.getItem("ERISA_SHOW_ARCHIVED_PROJECTS")
        ? JSON.parse(
            window.localStorage.getItem("ERISA_SHOW_ARCHIVED_PROJECTS")
          )
        : false
    );
  }

  componentWillUnmount() {
    this.isMountedVal = 0;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchBrokercompaniesData !== this.props.brokercompanies) {
      return true;
    }
  }

  renderBrokerCompaniesProjects(company, type, company_id) {
    let count = 0;
    const broker_url = this.props.activeItem.org_route;
    let client_url = type;
    let client_name = "";
    return company.map((project, pid) => {
      if (type === "recent" && !this.companyList.has(project.emporg_id)) {
        return "";
      }
      if (type === "recent") {
        client_url = project.EmployersOrg.route_url;
        client_name = project.EmployersOrg.Organization
          ? project.EmployersOrg.Organization.name
          : "N/A";
      }
      project.broker_url = broker_url;
      project.client_url = client_url;
      if (project.status === "Active") {
        count++;
        let currentClass = "col-6 col-sm-4 col-lg-4 col-xl-3 tile";
        if (count > 3 && this.state.showAll !== true && type !== "recent") {
          currentClass = "col-6 col-sm-4 col-lg-4 col-xl-3 tile hidden";
        }
        const propsData = {
          project,
          client_name,
          currentClass,
          handleClick: (data) => this.setState(data),
          company_id,
        };
        return <ProjectCard key={pid} {...propsData}></ProjectCard>;
      } else {
        return null;
      }
    });
  }

  renderBrokerCompaniesArchivedProjects(company, route) {
    const broker_url = this.props.activeItem.org_route;
    const client_url = route;

    return company.map((project, pid) => {
      project.broker_url = broker_url;
      project.client_url = client_url;

      const { project_state, status, updatedAt } = project;

      if (
        (project_state === "Final" || project_state === "Done") &&
        status === "Active" &&
        isThreeMonthsOlder(new Date(), new Date(updatedAt)) &&
        this.checkUserData() === 1
      ) {
        this.props.updateProjectStatusType({
          id: project.id,
          status: "Archived",
        });
      }

      if (project.status === "Archived" && this.props.archived === true) {
        return (
          <div
            className="col-6 col-sm-4 col-lg-4 col-xl-3 tile"
            key={`comapny_project_${pid}`}>
            <div
              className={`tileContent bg_${project.status_color_code}`}
              style={{ background: "#9E9E9E" }}>
              <div
                className="title"
                onClick={(e) =>
                  this.handleRedirectionToProjectEditPage(project)
                }>
                [Archived] {project.name}
              </div>
              <div className="tileFooter">
                <div className="titleId">{/*project.StickyNotes.length*/}</div>
              </div>
            </div>
          </div>
        );
      }
      return "";
    });
  }
  markFavorite(clientId) {
    this.props
      .markFavorite({
        broker_id: this.props.activeItem.id,
        company_id: clientId,
      })
      .then((res) => {
        this.props.fetchBrokerCompanies(this.props.activeItem.id);
        toast.success(res.response_desc);
      });
  }
  unMarkFavorite(clientId) {
    this.props
      .unMarkFavorite({
        broker_id: this.props.activeItem.id,
        company_id: clientId,
      })
      .then((res) => {
        this.props.fetchBrokerCompanies(this.props.activeItem.id);
        toast.success(res.response_desc);
      });
  }

  renderBrokerCompanies() {
    if (this.props.companies && this.props.companies.length <= 0) {
      return (
        <div className="col-12 col-content">
          {" "}
          There are 0 companies assigned to this consultant.
        </div>
      );
    } else {
      return this.props.brokercompanies.map((company, companyId) => {
        if (company.employer.status !== "Active" || !company.status) {
          return <></>;
        }
        socket.on(`company-${company.employer.id}`, (data) => {
          this.props.fetchBrokerCompanies(this.props.activeItem.id);
          this.props.fetchAllRecentProjects({
            broker_id: this.props.activeItem.id,
          });
        });

        this.companyList.add(company.employer.id);

        let displayClass = "col-12 col-content";
        let favHtml = (
          <a href="#" onClick={() => this.markFavorite(company.employer.id)}>
            <i className="fas fa-star"></i>{" "}
            <span className="d-none d-md-inline-block">Add Client</span>
          </a>
        );
        if (this.checkUserData() === 2 && this.props.favClients === false) {
          displayClass = "col-12 col-content hidden";
        }
        if (this.checkUserData() === 2 && company.favorite) {
          favHtml = (
            <Link
              className="marked"
              onClick={() => this.unMarkFavorite(company.employer.id)}>
              <i className="fas fa-star"></i>{" "}
              <span className="d-none d-md-inline-block">My Client</span>
            </Link>
          );
          displayClass = "col-12 col-content";
        } else if (this.checkUserData() !== 2) {
          favHtml = "";
        }

        return (
          <div
            key={`company_cid_${company.employer_id}_${companyId}`}
            className={displayClass}
            id={`company_${company.employer_id}`}>
            <div className="titleBar bottom">
              <h5>{company.name}</h5>
              <ul className="list-style-none">
                <li>
                  <a
                    data-intercom-target="compliance-calendar"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${company.employer.compliance_calender}`}>
                    <i className="far fa-calendar"></i>{" "}
                    <span className="d-none d-md-inline-block">
                      Compliance Calendar
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${company.employer.plan_manager}`}>
                    <i className="fa fa-link"></i>{" "}
                    <span className="d-none d-md-inline-block">
                      Plan Manager
                    </span>
                  </a>
                </li>
                <li>
                  <Link
                    to={`/members/company/${company.employer.Organization.org_route}`}>
                    <i className="fas fa-users"></i>{" "}
                    <span className="d-none d-md-inline-block">View Users</span>
                  </Link>
                </li>
                <li data-intercom-target="add-client">{favHtml}</li>
              </ul>
            </div>
            <div className="row tileRow">
              {this.renderBrokerCompaniesProjects(
                company.projects,
                company.employer.route_url,
                company.employer_id
              )}
              {this.renderBrokerCompaniesArchivedProjects(
                company.projects,
                company.employer.route_url
              )}
              {this.checkUserData() === 1 && (
                <div className="col-6 col-sm-4 col-lg-4 col-xl-3 tile">
                  <Link
                    onClick={(e) => this.handleActiveCompany(company)}
                    to={`/project-new/${this.props.activeItem.org_route}/${company.employer.route_url}`}
                    className="tileContent createNewProject">
                    <div className="new">
                      <i className="fas fa-plus"></i>
                      <h5>Create New Project</h5>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  }
  renderRecentBrokerCompanies() {
    if (this.props.recentClients && this.props.recentClients.length <= 0) {
      return <div className="col-12 col-content"></div>;
    } else {
      return (
        <div className="col-12 col-content">
          <div className="titleBar">
            <h5 style={{ color: "#e67e21" }}>Recently Viewed</h5>
          </div>
          <div className="row tileRow">
            {this.renderBrokerCompaniesProjects(
              this.props.recentClients,
              "recent"
            )}
          </div>
        </div>
      );
    }
  }

  renderBrokerName() {
    if (this.checkUserData() !== 3 && this.props.brokerName) {
      return <span>{this.props.brokerName.name}'s Dashboard</span>;
    } else if (
      this.checkUserData() === 3 &&
      this.props.brokercompanies.length > 0
    ) {
      return <span>{this.props.brokercompanies[0].name}'s Dashboard</span>;
    }
  }
  renderRedirectWindow() {
    if (this.state.redirectWindow) {
      return (
        <div className="redirectWindow">
          <div className="redirectContent">
            <h4>Update Project Status</h4>
            {(() => {
              if (!this.state.projectUpdateStatus) {
                return (
                  <div>
                    <p
                      style={{
                        "padding-top": "20px",
                        "padding-bottom": "20px",
                      }}>
                      Continue to update project status to{" "}
                      <b> {this.state.redirectWindowData.status} </b>
                    </p>
                    <div className="btnRow">
                      <button
                        class="custom-btn"
                        onClick={(e) => this.handleProjectStatus()}>
                        Continue
                      </button>
                      <button
                        class="custom-btn ml-3 cancel"
                        onClick={(e) => {
                          this.setState({
                            redirectWindow: false,
                            redirectWindowData: {},
                            project: "",
                            status: "",
                          });
                        }}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              } else if (this.state.projectUpdateStatus) {
                return (
                  <div>
                    <p>{this.state.projectUpdateStatusResponse}</p>
                    <div className="btnRow">
                      <button
                        class="custom-btn"
                        onClick={(e) => {
                          this.setState({
                            redirectWindow: false,
                            redirectWindowData: {},
                            projectUpdateStatus: false,
                            projectUpdateStatusResponse: "",
                          });
                        }}>
                        Continue
                      </button>
                    </div>
                  </div>
                );
              }
            })()}

            {(() => {
              if (this.state.projectUpdateStatusLoader) {
                return <Loader />;
              }
            })()}
          </div>
        </div>
      );
    }
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  render() {
    let r = Math.random().toString(36).substring(7);

    return (
      <div key={r} className="col-12 col-sm-9 col-md-9 col-xl-9 content">
        {(() => {
          if (this.state.redirect === false) {
            return <Loader />;
          }
        })()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
        <div className="row">
          <div className="col default">
            <div className="row dashboardContainer">
              <div className="col-12 col-custom">
                <h5>{this.renderBrokerName()}</h5>
                <div className="switchArea ">
                  <div className="marginLeft d-none">
                    <label>Show All Clients' Projects</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          this.handleShowAllProject();
                        }}
                        checked={this.state.showAll}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>

                  {this.checkUserData() === 2 && (
                    <div className="d-inline-block marginLeft">
                      <label>Show All Clients</label>
                      <Switch
                        className="switch"
                        onChange={(e) => {
                          this.handleShowFavorite();
                        }}
                        checked={this.props.favClients}
                      />
                    </div>
                  )}
                  <div className="d-inline-block">
                    <label>Show Archived Projects </label>
                    <Switch
                      className="switch"
                      onChange={(e) => {
                        this.handleShowArchivedProject();
                      }}
                      checked={this.props.archived}
                    />
                  </div>
                  {this.checkUserData() === 1 &&
                    this.props.activeItem !== null && (
                      <div className="d-inline-block">
                        <Link
                          className="secondary-btn"
                          to={`/create-employer-company/${this.props.activeItem.org_route}`}
                          style={{ color: "#212529" }}>
                          Create Client Company
                        </Link>
                      </div>
                    )}
                </div>
              </div>
              {this.renderRecentBrokerCompanies()}

              {this.renderBrokerCompanies()}
            </div>
          </div>
        </div>
        {this.renderRedirectWindow()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    brokercompanies: state.fetchBrokercompaniesData,
    companies: state.fetchBrokercompaniesData,
    brokerName: state.activeDashboardItem,
    activeItem: state.activeDashboardItem,
    recentClients: state.recentClients,
    trello: state.trello,
    favClients: state.showFavClients,
    archived: state.showArchivedProjects,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    showArchivedProjects,
    showFavClients,
    activeDashboardItem,
    checkBroker,
    updateActiveComapny,
    updateProjectStatus,
    updateActiveProjectDetails,
    fetchAllClientsForActiveBroker,
    fetchBrokerCompanies,
    fetchAllRecentProjects,
    markFavorite,
    unMarkFavorite,
    fetchAllUsersForActiveBroker,
    fetchAllBrokersForActiveClient,
    updateProjectStatusType,
    getAllTrelloListsIds,
    getListOfCard,
  })(DashboardContent)
);
