import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import DashboardNav from '../../nav/DashboardNav.jsx';
import {fetchAllUsers, fetchAllBrokers, fetchAllClients, 
	fetchAllUsersForActiveBroker,
	activeDashboardItem, updatableBroker, updatableCompany,
	fetchAllClientsForActiveBroker, fetchAllBrokersForActiveClient, deleteInvitedUser,
	deleteUser, deleteCompany, deleteBroker, fetchUserById, deactivateUser, activateUser} 
	from '../../../actions';

import Toast from '../../common/Toast'
import { ToastContainer, toast } from 'react-toastify';
import './assets/companyOverView.css';
import Loader from '../../common/Loader';
import AdminModal from '../../common/AdminModal';
import Checkbox from '../../common/Checkbox';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

class CompanyOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
		redirectUser: null,
		loading: false,
		show_modal:{
			status:false,
			action_btn_text:null,
			title:null,
			type:null,
			activeBroker:null,
			deleteUser: null,
			deleteBroker: null,
			deleteCompany: null,
			deleteEntity: null
		},
		deactivateEntity: null

	};

  }
  toggleCheckbox = (userId, status) => {
    if(status === false){
		this.setState({deactivateEntity: { "user_id": userId, 'status': status }})
		return true;
	}
	else{
		if(this.checkUserData() === 1){
			this.setState({deactivateEntity: { "user_id": userId, 'status': status }})
		}
		return false;
	}
  }

  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  componentDidMount() {
	document.title = "Entities";
	if(this.props.user){
		this.props.fetchAllUsers({"user":this.props.user.id});
		this.props.fetchAllBrokers(2);
		this.props.fetchAllClients(3);
	}	
	if(this.props.activeItem && this.props.activeItem.data !== null){
		this.setState({activeBroker:this.props.activeItem.name});			
	}
  }
  componentDidUpdate(prevProps) {
	if(prevProps.user !== this.props.user){
		this.props.fetchAllUsers({"user":this.props.user.id});
		this.props.fetchAllBrokers(2);
		this.props.fetchAllClients(3);
	}
	if(prevProps.activeItem !== this.props.activeItem){
		this.setState({activeBroker:this.props.activeItem.name});			
	}
  }
  // Show Errors 
  deleteInvitedUser(){
	this.setState({ loading: true})
	this.props.deleteInvitedUser({id: this.state.deleteUser.id}).then((response) => {
		this.setState({deleteUser: null, deleteEntity:null});
		this.props.fetchAllUsers();
		this.setState({ loading: false})
	})
  }
  /*
  deleteUser(){
	this.setState({ loading: true})
	this.props.deleteUser({id: this.state.deleteUser.id}).then((response) => {
		this.setState({deleteUser: null, deleteEntity:null})
		this.props.fetchAllUsers();
		this.setState({ loading: false})
	})
  }
  */
  deleteBroker(){
	this.setState({ loading: true})
	this.props.deleteBroker({ id: this.state.deleteBroker.id}).then((response) => {
		this.setState({deleteBroker: null, deleteEntity:null})
		this.props.fetchAllBrokers();
		this.setState({ loading: false})
	})

 }
 deleteCompany(){
	this.setState({ loading: true})
	this.props.deleteCompany({ id: this.state.deleteCompany.id}).then((response) => {
		this.setState({deleteCompany: null, deleteEntity:null})
		this.props.fetchAllClients();
		this.setState({ loading: false})
	})
	
 }
 deactivateUser(){
	const userObj = this.state.deactivateEntity;
	this.setState({ loading: true, deactivateEntity:null})
	if(userObj.status){
		this.props.activateUser({ id: userObj.user_id}).then((response) => {
			toast.success(response.response_desc);
			this.props.fetchAllUsers().then(() =>{
				this.setState({ loading: false})
			})
		})
	}else{
		this.props.deactivateUser({ id: userObj.user_id}).then((response) => {
			toast.success(response.response_desc);
			this.props.fetchAllUsers().then(() =>{
				this.setState({ loading: false})
			})
			
		})
	}
	
 }
  renderRedirectWindow(){
	let button = null;
	if(this.state.deleteEntity == 'user'){
		button = <button class="custom-btn ml-3 cancel" onClick={(e) =>{this.setState({deleteEntity:null }); this.deleteInvitedUser()}}>Yes</button>
	}
	else if(this.state.deleteEntity == 'broker'){
		button = <button class="custom-btn ml-3 cancel" onClick={(e) =>{this.setState({deleteEntity:null }); this.deleteBroker()}}>Yes</button>
	}
	else if(this.state.deleteEntity == 'company'){
		button = <button class="custom-btn ml-3 cancel" onClick={(e) =>{ this.setState({deleteEntity:null }); this.deleteCompany()}}>Yes</button>
	}
	if(this.state.deleteEntity != null){
		return(
			<div className="redirectWindow">
			<div className="redirectContent">
				<p>Are you sure, you want to delete?</p>
				<div className="btnRow">
					<button class="custom-btn" onClick={(e) =>{this.setState({deleteEntity:null })}}>No</button>
					{button}
				</div>
			</div>
			</div>  
		)
	}
	else if(this.state.deactivateEntity != null){
		let message = (
			<p>Are you sure, you want to deactivate?</p>
		)
		if(this.state.deactivateEntity.status){
			message = (
				<p>Are you sure, you want to activate?</p>
			)
		}
		return(
			<div className="redirectWindow">
			<div className="redirectContent">
				{ message }
				<div className="btnRow">
					<button class="custom-btn" onClick={(e) =>{this.setState({deactivateEntity:null })}}>No</button>
					<button class="custom-btn ml-3 cancel" onClick={(e) =>{ this.deactivateUser()}}>Yes</button>
				</div>
			</div>
			</div>  
		)
	}

  }
  getUserEntity(user){
	this.setState({ redirectUser: "loading"})
	  this.props.fetchUserById(user.id).then(()=>{
		  //this.setState({ redirectUser: "redirect"});
		  return this.props.history.push(`/members/user/${(user.firstName).replace(/ /g,"-")}`);
	  })
  }
  getBrokerUsersAndClients(brokerDetails, type){

    
  }
 
  redirectToUser(){
	if(this.state.redirectUser === "loading"){
		return <Loader />
	}
	else if(this.state.redirectUser === "redirect")
	{
		return <Loader />
	}
	
  }
 
  // render all  user list 
  renderUserList(){
	  if(this.props.user_list.status === "loading"){
		return <Loader />
	  }
	  else if(this.props.user_list.response_data && this.props.user_list.response_data.length > 0){
		return(			
			<table className="table table-striped custom-strips">
			    <thead>
					<tr>
					
						<th scope="col" colSpan="2">Name</th>
						<th scope="col" colSpan="2">Type</th>
						<th scope="col" colSpan="2">Organization</th>
						<th scope="col"></th>
						<th scope="col">Options</th>
					</tr>
				</thead>
				<tbody>
                     {(()=>{
						 return this.props.user_list.response_data.map((user, id) => {	
								 let bgColor = 'grey-column';	
								 let type = 'Deactivated';
								 
								if(user.Organization){	
									if(user.User.status){
										bgColor = '';	
										type = user.Organization.UserType.name;
									 }					
									return(
										<tr key={id} className={ bgColor }>
											<td></td>
											<td>{user.User.firstName +" "+ user.User.lastName}</td>
											<td className="separater"><div>&nbsp;</div></td>
											<td>{type}</td>
											<td className="separater"><div>&nbsp;</div></td>
											<td>{user.Organization.name}</td>
											<td className="separater"><div>&nbsp;</div></td>
											<td className="rowAction">
											<div className="customFlexBox">
												<div className="" onClick = {() => this.getUserEntity(user.User)} ><i className="fas fa-edit"></i></div>
												
											</div>
											</td>
										</tr>
									)	
								}
							 })
						 
						 	 
						 
					 })()}
					 {(()=>{
						return this.props.user_list.response_invited_data.map((user, id) => {	
							
							let bgColor = 'declined-column';	
							let type = (
								<td>Declined</td>
								
							);
							
							   if(user.Organization){	
									if(user.status === 'pending'){
										bgColor = '';	
										type = (
											<td style={{color: "#c9652e"}}>Needs to Register</td>
										)
									}					
								   return(
									   <tr key={id} className={bgColor}>
										   
										   <td colSpan="2">{user.email}</td>
										   <td className="separater"><div>&nbsp;</div></td>
										   {type}
										   <td className="separater"><div>&nbsp;</div></td>
										   <td>{user.Organization.name}</td>
										   <td className="separater"><div>&nbsp;</div></td>
										   <td className="rowAction">
										   <div className="customFlexBox">
										   		<div className="" onClick = {() => this.setState({deleteUser: user,deleteEntity: 'user' })} ><i className="fas fa-times"></i></div>
										   </div>
										   </td>
									   </tr>
								   )	
							   }
							})
						
							 
						
					})()}
				</tbody>
		    </table>
			
		)
	  }
	  else{
		  return <p className="noRecordsFound">No Records Found</p>
	  }
  }
   // render all  user list 
   renderBrokerList(){
	if(this.props.broker_list.status === "loading"){
	  return <Loader />
	}
	else if(this.props.broker_list.response_data && this.props.broker_list.response_data.length > 0){	
	    return(			
		  <table className="table table-striped custom-strips">
			  <thead>
				  <tr>
					  <th scope="col" colSpan="2">Company Name</th>
					  <th scope="col" colSpan="2">Type</th>
					  <th scope="col">Options</th>
				  </tr>
			  </thead>
			  <tbody>
				   {(()=>{
					   
						   return this.props.broker_list.response_data.map((broker, id) => {									
							  return(
								  <tr key={id}>
									  <td>
										  <Link  onClick = {() => {this.getBrokerUsersAndClients(broker, 'broker')} } to={`/members/${broker.org_route}`}>{broker.name}</Link>
									  </td>
									  <td className="separater"><div>&nbsp;</div></td>
									  <td>Consultant</td>
									  <td className="separater"><div>&nbsp;</div></td>
									  <td className="rowAction">
									  <div className="customFlexBox">
										  <Link className="" onClick={ () => { this.props.updatableBroker(broker) }}  to={`/members/consultant/${broker.org_route}/edit`}><i className="fas fa-edit"></i></Link>
									  </div>
									  </td>
								  </tr>
							  )	
						   })
					   
				   })()}	   
				  
			  </tbody>
		  </table>
	  )
	}
	else{
		return <p className="noRecordsFound">No Records Found</p>
	}
}

   // render all  user list 
   renderClientsList(){
	if(this.props.client_list.status === "loading"){
	  return <Loader />
	}
	else if(this.props.client_list.response_data && this.props.client_list.response_data.length > 0){	
		
	  return(			
		  <table className="table table-striped custom-strips">
			  <thead>
				  <tr>
					  <th scope="col" colSpan="2">Client Name</th>
					  <th scope="col">Options</th>
				  </tr>
			  </thead>
			  <tbody>
				   {(()=>{
					   return this.props.client_list.response_data.map((broker, id) => {
						let bgColor = 'grey-column';	
						if(broker.EmployersOrg && broker.EmployersOrg.status === 'Active'){
						   bgColor = '';	
						}	
							  return(
								  <tr key={id} className={ bgColor }>
									  <td><Link  onClick = {() => {this.getBrokerUsersAndClients(broker, 'client')} } to={`/members/company/${broker.org_route}`}>{broker.name}</Link></td>
									  
									  <td className="separater"><div>&nbsp;</div></td>									  
									  <td className="rowAction">
									  <div className="customFlexBox">
										  <Link className="" onClick={ () => { this.props.updatableCompany(broker) }}  to={`/members/company/${broker.org_route}/edit`}><i className="fas fa-edit"></i></Link>
									  </div>
									  </td>
								  </tr>
							  )	
						   })
					   
				   })()}
				   
				  
			  </tbody>
		  </table>
	  )
	}
	else{
		return <p className="noRecordsFound">No Records Found</p>
	}
}



renderAddNewItemOptions(type){
	let activeBroker = '';	
	if(this.props.activeItem){		
		activeBroker = 	this.props.activeItem.name
	}
	if((type ==="user") && (this.props.match.path ===  "/members/:name")){
		return (
			<div className="addNew">
				<div onClick={() => this.addNewItem({type:'invite_user'})}><i className="fas fa-plus"></i> <span>Invite New User</span></div>
			</div>
		)
	}
	else if((type ==="broker")){
		return (
			<div className="addNew">
				<div>
					<Link to={`/create-new-consultant/`}>
						<i className="fas fa-plus"></i> <span> Create Consultant Company</span>
					</Link>	
				</div>

			</div>	
		)
	}
	
	else if((type ==="clients") && (this.props.match.path ===  "/members/:name")){
		return (
			<div className="addNew">
				<div>
				<Link to={`/create-employer-company/${activeBroker}`}>
						<i className="fas fa-plus"></i> <span> Add New Client</span>
				</Link>	
				</div>
			</div>	
		)
	}

	else{
		return(undefined);
	} 
	  
}

//Add New User Or Assign a additional Broker
addNewItem(e){
	let newStatus = {
		    action_btn_text:"Send Invite",
			title:"Create New Admin",
			status:true,
			type:e.type,
			superAdmin: e.superAdmin
	}
	this.setState({show_modal:newStatus})
	 
}

closeModal(){
	let newStatus = {
		action_btn_text:null,
		title:null,
		status:false,
		type:null
	}
	this.setState({show_modal:newStatus})
}

  

  render() {
	 if(this.state.loading === true){
		return <Loader />
	 }
	 if(this.checkUserData() == 2){
		this.getBrokerUsersAndClients(this.props.user.org[0].Organization, 'broker');
		return <Redirect to={`/members/${this.props.user.org[0].Organization.org_route}`} />;
	 }
	 else if(this.checkUserData() == 3){
		this.getBrokerUsersAndClients(this.props.user.org[0].Organization, 'client');
		return <Redirect to={`/members/company/${this.props.user.org[0].Organization.org_route}`} />;
	 }
     return(<div className="row contentArea mr-0">
	 <Toast /> 
	 { this.redirectToUser() }
	 <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />
	 <AdminModal  show_modal={this.state.show_modal} successTitle="Invite Sent" closeModal={() => this.closeModal()}/>
	   
			<div className="col-12 col-sm-12 col-md-12 col-xl-12">
				<div className="row cp">
					<div className="col default">
						<div className="row">
							{/*
							<div className="col-12 customFlexBox header-sec" >
							    <div><img src="/assets/img/icons/logo-full.png" className="img-fluid img" /></div>
							</div>
							*/}
							<div className="col-12">
								<div className="row mr-0 ml-0">
									<div className="col-xs-12 col-sm-12 col-lg-6">
									    <div className="tableContainer">
										    <div className="tileRow customFlexBox">
												<div className="title">
													Users
												</div>
												<div className="addNew">
													<div style={{'display':'inline-block', 'marginRight':'10px'}} onClick={() => this.addNewItem({type:'invite_user', superAdmin: true})}><i className="fas fa-plus"></i> <span>Create New Admin</span></div>
												</div>	

												{ this.renderAddNewItemOptions('user')}
												
											</div>
											<div className="table-responsive">
										      {this.renderUserList()}	
											</div>
										</div>
									</div>
									{(()=>{
										
											return(
												<div className="col-xs-12 col-sm-12 col-lg-6">
													<div className="tableContainer fixedWidth">
														<div className="tileRow customFlexBox">
															<div className="title">
															Consultant
															</div>
															{ this.renderAddNewItemOptions('broker')}												 
														</div>
														<div className="table-responsive">
														{this.renderBrokerList()}
														</div>
													</div>
												</div>
											)
										
									})()}
									
									<div className="col-xs-12 col-sm-12 col-lg-6">
									    <div className="tableContainer fixedWidth left">
										    <div className="tileRow customFlexBox">
												<div className="title">
												Employers
												</div>
												{ this.renderAddNewItemOptions('clients')}												 
											</div>
											<div className="table-responsive">
											  {this.renderClientsList()}
											</div>
										</div>
									</div>




								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			{this.renderRedirectWindow()}
		</div>
		

		);

  }
}

function mapStateToProps(state){

    return{			
		user_list:state.users_list,
		broker_list:state.broker_list,
		client_list:state.client_list,
		user:state.user,
		toastMsg:state.toastMsg,
		activeItem:state.activeDashboardItem
    }
}
export default withRouter(
 connect(mapStateToProps, { deactivateUser, activateUser, fetchUserById, fetchAllUsers,deleteUser,deleteCompany, deleteBroker, updatableBroker, updatableCompany, fetchAllBrokers,fetchAllClients, fetchAllUsersForActiveBroker, activeDashboardItem, fetchAllClientsForActiveBroker, fetchAllBrokersForActiveClient, deleteInvitedUser})(CompanyOverView)
);