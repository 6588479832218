import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import {
  fetchAllBrokers,
  fetchAllClientsForActiveBroker
} from "../../../actions";
import { AWS_URL } from "../../../apis/api-config"
import Loader from "../../common/Loader";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ComplianceCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      error: "",
      success: ""
    };
  }
  renderRedirect = (org_route) => {
    if (this.state.redirect) {
      return <Redirect to={`/compliance-Calendar/${org_route}`} />
    }
  }
  componentDidMount() {
    document.title = "Compliance calendar/plan manager"

    this.props.fetchAllBrokers();
    this.setState({ org_type: this.props.createType });
    if (this.checkUserData() == 2) {
      this.props.fetchAllClientsForActiveBroker(
        this.props.user.org[0].Organization.id
      ).then(() => {
       // this.props.history.push(`/compliance-Calendar/${org_route}`);
        this.setState({redirect: true})
      })
    }
   
  }
  checkUserData() {
    if (this.props.user === null) {
      return null;
    } else {
      return this.props.user.type_id;
    }
  }
  render() {

    if (this.checkUserData() == 2) {
      if(this.state.redirect == false){
        return <Loader />;
      }else{
        return (
          //this.props.history.push(`/compliance-Calendar/${this.props.user.org[0].Organization.org_route}`)
          this.renderRedirect(this.props.user.org[0].Organization.org_route)
        );
      }
      
    } else if (this.checkUserData() == 3) {
      return (
        <Redirect
          to={`/compliance-Calendar/${this.props.user.org[0].Organization.org_route}`}
        />
      );
    }
    if (this.props.brokerList.status === "loading") {
      return <Loader />;
    } else if (
      this.props.brokerList.response_data &&
      this.props.brokerList.response_data.length > 0
    ) {
      return (
        <table
          className="table table-striped custom-strips calendarTable"
          style={{ marginTop: "20px" }}
        >
          <thead>
            <tr>
              <th scope="col" colSpan="2">
              Consultant Company Name
              </th>
              <th scope="col" colSpan="2">
                Logo
              </th>
              <th scope="col" colSpan="2">
                Organization Login URL
              </th>
              <th scope="col">Client Compliance Calendars</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              return this.props.brokerList.response_data.map((broker, id) => {
                return (
                  <tr>
                    <td>{broker.name}</td>
                    <td className="separater">
                      <div>&nbsp;</div>
                    </td>
                    <td>
                      <img
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                        src={AWS_URL + broker.org_logo}
                        className="img-fluid"
                        alt={broker.name}
                      />
                    </td>
                    <td className="separater">
                      <div>&nbsp;</div>
                    </td>
                    <td>{broker.org_route}</td>
                    <td className="separater">
                      <div>&nbsp;</div>
                    </td>
                    <td className="rowAction">
                      <div className="customFlexBox">
                        {this.renderRedirect(broker.org_route)}
                        <Link
                          className=""
                          style={{ cursor:"pointer" }}
                          to={`/compliance-Calendar/${broker.org_route}`}
                         
                        >
                          <i className="fas fa-eye"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              });
            })()}
          </tbody>
        </table>
      );
    }
    else{
      return (
        null
      )
    }
  }
}
function mapStateToProps(state) {
  return {
    brokerList: state.broker_list,
    user: state.user
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchAllBrokers, fetchAllClientsForActiveBroker })(
    ComplianceCalender
  )
);
